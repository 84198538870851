!(function (n) {
  void 0 === window.myFlatpickrPlugins && (window.myFlatpickrPlugins = []);
  let e = function () {
    return function (e) {
      let a = {
          ranges: void 0 !== e.config.ranges ? e.config.ranges : {},
          rangesOnly: void 0 === e.config.rangesOnly || e.config.rangesOnly,
          rangesAllowCustom:
            void 0 === e.config.rangesAllowCustom || e.config.rangesAllowCustom,
          rangesCustomLabel:
            void 0 !== e.config.rangesCustomLabel
              ? e.config.rangesCustomLabel
              : 'Custom Range',
          rangesNav: n('<ul>').addClass(
            'nav flex-column flatpickr-predefined-ranges'
          ),
          rangesButtons: {},
        },
        s = function (e) {
          return (
            (a.rangesButtons[e] = n('<button>')
              .addClass('nav-link btn btn-link')
              .attr('type', 'button')
              .text(e)),
            a.rangesNav.append(
              n('<li>').addClass('nav-item d-grid').append(a.rangesButtons[e])
            ),
            a.rangesButtons[e]
          );
        },
        t = function (s) {
          let t = !1;
          if (
            (a.rangesNav.find('.active').removeClass('active'), s.length > 0)
          ) {
            let r = dayjs(s[0]),
              l = s.length > 1 ? dayjs(s[1]) : r;
            for (let [o, i] of Object.entries(a.ranges))
              if (
                i.length > 0 &&
                r.isSame(i[0], 'day') &&
                l.isSame(i[1], 'day')
              ) {
                a.rangesButtons[o].addClass('active'), (t = !0);
                break;
              }
          }
          s.length > 0 &&
            !t &&
            a.rangesButtons.hasOwnProperty(a.rangesCustomLabel) &&
            (a.rangesButtons[a.rangesCustomLabel].addClass('active'),
            n(e.calendarContainer).removeClass(
              'flatpickr-predefined-ranges-only'
            ));
        };
      return {
        onReady(r) {
          for (let [l, o] of Object.entries(a.ranges))
            s(l).on('click', function () {
              n(this).trigger('focus'), e.setDate([o[0], o[1]], !0), e.close();
            });
          a.rangesNav.children().length > 0 &&
            (a.rangesOnly &&
              a.rangesAllowCustom &&
              s(a.rangesCustomLabel).on('click', function () {
                n(this).trigger('focus'),
                  a.rangesNav.find('.active').removeClass('active'),
                  n(this).addClass('active'),
                  n(e.calendarContainer).removeClass(
                    'flatpickr-predefined-ranges-only'
                  );
              }),
            n(e.calendarContainer).prepend(a.rangesNav),
            n(e.calendarContainer).addClass('flatpickr-has-predefined-ranges'),
            t(r));
        },
        onValueUpdate(n) {
          t(n);
        },
      };
    };
  };
  window.myFlatpickrPlugins.push(new e());
})(jQuery);
